import React from "react";
import styling from "./Project.module.css";
import { FaFolderOpen } from "react-icons/fa";

export default function Project({ details }) {
  return (
    <div className={styling.Project}>
      <div className={styling.ProjectIcons}>
        <div>
          <p>
            <FaFolderOpen />
          </p>
        </div>
        <div>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={details.gitHubLink}
            >
              {details.icon[0]}
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={details.websiteLink}
            >
              {details.icon[1]}
            </a>
          </p>
        </div>
      </div>

      <div className={styling.description}>
        <div>
          <h3>{details.title.split("-").join(" ")}</h3>
          <p>{details.description}</p>
        </div>
      </div>
      <div className={styling.languages}>
        <p>{details.language ? details.language : "JavaScript"}</p>
        <p>
          {details.publishDate
            .slice(0, details.publishDate.indexOf("T") - 3)
            .split("-")
            .reverse()
            .join(" ")}
        </p>
      </div>
    </div>
  );
}
