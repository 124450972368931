import React from "react";
import styling from "./Sidebar.module.css";
import { NavLink } from "react-router-dom";
import { BsChevronDoubleLeft } from "react-icons/bs";

export default function Sidebar() {
  return (
    <div className={styling.sidebar}>
      <NavLink exact to="/blog">
        <BsChevronDoubleLeft />
      </NavLink>
    </div>
  );
}
