import axios from "axios";

export const devto = async () => {
  try {
    let { data } = await axios.get(
      "https://dev.to/api/articles?username=suhelhasan"
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const devpost = async (id) => {
  try {
    let { data } = await axios.get(`https://dev.to/api/articles/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};
