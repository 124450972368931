import React, { useEffect, useState } from "react";
import { devpost } from "../../../API/dev";
import styling from "./Post.module.css";
import { FaEye } from "react-icons/fa";
import Loader from "../../../UI/Loader/Loader";
import Sidebar from "./Sidebar/Sidebar";

export default function Post({ match }) {
  let [post, setPost] = useState("");

  let id = match.path.split("-");
  id = id[id.length - 1];

  useEffect(() => {
    (async () => {
      setPost(await devpost(id));
    })();
  }, [id]);

  return (
    <>
      {post ? (
        <div className={styling.Post} key={post.id}>
          <div className={styling.innerPost}>
            <div className={styling.PostInfo}>
              <Sidebar details={post} />
            </div>
            <div className={styling.PostInnerDiv}>
              <div className={styling.PostCoverImage}>
                <img alt="ICON" src={post.cover_image} />
              </div>

              <div className={styling.PostContent}>
                <h1>{post.title}</h1>
                {post.tags.map((each, index) => (
                  <button
                    className={styling[`tagButton${index + 1}`]}
                    key={each}
                  >{`#${each}`}</button>
                ))}
                <div className={styling.WriterDetails}>
                  <div>
                    <img alt="ICON" src={post.user.profile_image_90} />
                    <p className={styling.userName}>{post.user.name}</p>
                  </div>
                  <div>
                    <p>{post.public_reactions_count}</p>

                    <p className={styling.icon}>
                      <FaEye />
                    </p>

                    <p>{post.readable_publish_date}</p>
                  </div>
                </div>
                <div
                  className={styling.PostText}
                  dangerouslySetInnerHTML={{ __html: post.body_html }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}
