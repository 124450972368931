import React from "react";
import styling from "./Home.module.css";
import About from "./About/About";
import LandingPage from "./LandingPage/LandingPage";
import GetInTouch from "./GetInTouch/GetInTouch";
import HomeProject from "./HomeProject/HomeProject";
import HomeBlog from "./HomeBlog/HomeBlog";

export default function Home() {
  return (
    <div className={styling.Home}>
      <LandingPage />
      <About />
      <HomeProject />
      <HomeBlog />
      <GetInTouch />
    </div>
  );
}
