import React from "react";
import styling from "./HomeBlog.module.css";
import Button from "../../../UI/Reusable/Button/Button";
import { NavLink } from "react-router-dom";
import Heading from "../../../UI/Reusable/Heading/Heading";

export default function HomeBlog() {
  return (
    <div className={styling.HomeBlog}>
      <Heading moveRight={true} title=".My Articles" />

      <p className={styling.textContent}>
        What good is information if it is not diffused into other minds? To
        convey the knowledge I have gained, I write technical blogs and so far ,
        I have tried to simplify and present basic concepts of "Javascript" and
        "React" through my perspective which will make the basic concepts easier
        for beginners!
      </p>
      <NavLink to="/blog">
        <Button title="View My Blog" />
      </NavLink>
    </div>
  );
}
