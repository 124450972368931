import React from "react";
import { FaGithub, FaLinkedinIn, FaCodepen } from "react-icons/fa";
// import { FiYoutube } from "react-icons/fi";

import styling from "./Sidebars.module.css";

export default function Sidebars() {
  return (
    <>
      <div className={styling.iconsDiv}>
        <div className={styling.socialIcons}>
          <div>
            <a
              href="https://github.com/suhelhasan"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub />
            </a>
          </div>
          {/* <div>
            <a
              href="https://twitter.com/ProgrammingEra"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter />
            </a>
          </div> */}
          <div>
            <a
              href="https://www.linkedin.com/in/suhelhasan11/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedinIn />
            </a>
          </div>
          {/* <div>
            <a
              href="https://www.youtube.com/channel/UCUnShWlwJ57J6tZ6pmDFObg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FiYoutube />
            </a>
          </div> */}
          <div>
            <a
              href="https://codepen.io/suhelhasan/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaCodepen />
            </a>
          </div>
        </div>
        <div className={styling.iconsLine}></div>
      </div>

      <div className={styling.emailDiv}>
        <a
          href="mailto:suhelhasan202@gmail.com?subject=Contacting threw website"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <p>suhelhasan202@gmail.com</p>
        </a>
        <div className={styling.emailLine}></div>
      </div>
    </>
  );
}
