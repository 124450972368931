import React, { useState, useEffect } from "react";
import "./App.css";
import { devto } from "./API/dev";
import { github } from "./API/github";
import { MyContext } from "./Context/context";
import Routing from "./Routing/Routing";
import themeStyle from "./Theme/appTheme.module.css";
import Navbar from "./UI/Navbar/Navbar";
import Sidebar from "./UI/Sidebars/Sidebars";
import Footer from "./UI/Footer/Footer";
function App() {
  let [devDetails, setDevDetails] = useState("");
  let [project, setProjects] = useState("");
  let [themeMode, setThemeMode] = useState("dark");

  // To set Blog Details
  useEffect(() => {
    (async () => {
      setDevDetails(await devto());
    })();
  }, []);

  // To set github project details
  useEffect(() => {
    (async () => {
      setProjects(await github());
    })();
  }, []);

  // To get theme from local storage
  useEffect(() => {
    let previousTheme = localStorage.getItem("preTheme");
    if (previousTheme) {
      setThemeMode(previousTheme);
    } else if (!getPreferColor()) {
      setThemeMode("light");
    }
  }, []);

  // To get prefer theme
  function getPreferColor() {
    if (!window.matchMedia) return;
    return window.matchMedia("(prefers-color-scheme:dark)");
  }
  //  To set theme color
  useEffect(() => {
    localStorage.setItem("preTheme", themeMode);
  }, [themeMode]);

  return (
    <div className={themeStyle[themeMode]}>
      <MyContext.Provider
        value={{ devDetails, project, themeMode, setThemeMode }}
      >
        <Navbar />
        <Sidebar />
        <Routing devDetails={devDetails} github={github} />
        <Footer />
      </MyContext.Provider>
    </div>
  );
}

export default App;
