import React from "react";
import styling from "./Sidebar.module.css";
import { BsX } from "react-icons/bs";
import { NavLink } from "react-router-dom";

export default function Sidebar({ closeSidebar }) {
  return (
    <div className={styling.Sidebar}>
      <div
        className={styling.SidebarExtraSpace}
        onClick={() => closeSidebar(false)}
      ></div>
      <div className={styling.SidebarContent}>
        <div className={styling.crossContent}>
          <div
            className={styling.crossSign}
            onClick={() => closeSidebar(false)}
          >
            <h1>
              <BsX />
            </h1>
          </div>
        </div>
        <div className={styling.contentLinks}>
          <div>
            <NavLink
              exact
              to="/"
              activeClassName={styling.activeLink}
              onClick={() => closeSidebar(false)}
            >
              <span className={styling.contentLinksNumber}>01</span> About
            </NavLink>
            <NavLink
              to="/blog"
              activeClassName={styling.activeLink}
              onClick={() => closeSidebar(false)}
            >
              <span className={styling.contentLinksNumber}>02</span> Blog
            </NavLink>
            <NavLink
              exact
              to="/projects"
              activeClassName={styling.activeLink}
              onClick={() => closeSidebar(false)}
            >
              <span className={styling.contentLinksNumber}>03</span> Projects
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
