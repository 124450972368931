import React from "react";
import styling from "./LandingPage.module.css";
import Button from "../../../UI/Reusable/Button/Button";

export default function LandingPage() {
  return (
    <div className={styling.LandingPage}>
      <div className={styling.LandingPageContent}>
        <div className={styling.headings}>
          <p>Hi, my name is</p>

          <h1 className={styling.myName}>Suhel Hasan.</h1>
          <h1 className={styling.tagLine}>I build things for the web.</h1>
        </div>

        <p className={styling.aboutMe}>
          I'm a software engineer, who loves to work with JavaScript,
          specializing in building high-performing websites, applications, and
          everything in between.
        </p>
        <a
          href="mailto:suhelhasan202@gmail.com?subject=Contacting threw website"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Button title="Get In Touch" />
        </a>
      </div>
    </div>
  );
}
