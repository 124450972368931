import React, { useContext } from "react";
import { MyContext } from "../../Context/context";
import { GoMarkGithub, GoLinkExternal } from "react-icons/go";
import styling from "./Projects.module.css";
import Project from "./Project/Project";
import Loader from "../../UI/Loader/Loader";
import Heading from "../../UI/Reusable/Heading/Heading";

export default function Projects() {
  let { project } = useContext(MyContext);

  if (project) {
    project.forEach((each) => {
      each.sortingRepo = each.created_at
        .slice(0, each.created_at.indexOf("T"))
        .split("")
        .map((each) => (each === "-" ? "" : each))
        .join("");
    });
    project.sort((a, b) => b.sortingRepo - a.sortingRepo);
    project = project.filter((each) => !each.fork);
    console.log(project);
  }

  return project ? (
    <div className={styling.Projects}>
      <Heading title="Some Things I have Built." />

      <div className={styling.ProjectsInner}>
        {project.map((eachProject) => {
          let project = {
            id: eachProject.id,
            coverPage: eachProject.homepage
              ? `http://api.screenshotlayer.com/api/capture?access_key=e07717d13d1a7e8c5ea8789aef734882&url=${eachProject.homepage}`
              : eachProject.owner.avatar_url,
            title: eachProject.name,
            description: eachProject.description,
            publishDate: eachProject.created_at,
            views: eachProject.forks_count,
            icon: [<GoMarkGithub />, <GoLinkExternal />],
            gitHubLink: eachProject.html_url,
            websiteLink: eachProject.homepage,
            language: eachProject.language,
          };
          return <Project details={project} />;
        })}
      </div>
    </div>
  ) : (
    <Loader />
  );
}
