import React, { useContext } from "react";
import { MyContext } from "../../Context/context";
import styling from "./Blogs.module.css";
import Blog from "./Blog/Blog";
import Loader from "../../UI/Loader/Loader";
import Heading from "../../UI/Reusable/Heading/Heading";
export default function Blogs() {
  const { devDetails } = useContext(MyContext);

  return devDetails ? (
    <div className={styling.BlogSection}>
      <Heading title="My Recent Thoughts." />

      <div className={styling.Blogs}>
        {devDetails.map((eachArticle) => {
          let details = {
            id: eachArticle.id,
            coverPage: eachArticle.cover_image,
            title: eachArticle.title,
            description: eachArticle.description,
            publishDate: eachArticle.readable_publish_date,
            views: eachArticle.public_reactions_count,
            link: `/blog/${
              eachArticle.title
                .split("")
                .map((each) => (each === " " ? "-" : each))
                .join("") +
              "-" +
              eachArticle.id
            }`,
            url: eachArticle.url,
          };
          return <Blog details={details} />;
        })}
      </div>
    </div>
  ) : (
    <Loader />
  );
}
