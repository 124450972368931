import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../Components/Home/Home";
import Blogs from "../Components/Blogs/Blogs";
import Post from "../Components/Blogs/Post/Post";
import NotFound from "../Components/NotFound/PageNotFound";
import Projects from "../Components/Projects/Projects";

export default function Routing({ devDetails, github }) {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/blog" component={Blogs} />
        {devDetails
          ? devDetails.map((eachItem) => (
              <Route
                exact
                path={`/blog/${
                  eachItem.title
                    .split("")
                    .map((each) => (each === " " ? "-" : each))
                    .join("") +
                  "-" +
                  eachItem.id
                }`}
                component={Post}
                key={eachItem.id}
              />
            ))
          : null}

        <Route exact path="/projects" component={Projects} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
}
