import React, { useState } from "react";
import styling from "./Navbar.module.css";
// import ToggleButton from "./ThemeToggleButton/ThemeToggleButton";
import { NavLink } from "react-router-dom";
import logo from "../../Assets/logo.png";
import Sidebar from "./SideBar/Sidebar";
export default function Navbar() {
  // CHANGING NAVBAR ON SCROLL
  let [navStyle, setNavStyle] = useState(styling.Navbar);
  let [openBurgerMenu, setOpenBurgerMenu] = useState(false);

  window.onscroll = function myFunction() {
    if (window.innerHeight / 7 > window.scrollY) {
      setNavStyle(styling.Navbar);
    } else {
      setNavStyle([styling.Navbar, styling.newNavbar].join(" "));
    }
  };

  return (
    <div className={navStyle}>
      <h1>
        <NavLink exact to="/">
          <img src={logo} alt="logo" />
        </NavLink>
      </h1>
      <div className={styling.Links}>
        <NavLink exact to="/" activeClassName={styling.activeLink}>
          <span className={styling.linkNumber}>01.</span> Home
        </NavLink>
        <NavLink to="/blog" activeClassName={styling.activeLink}>
          <span className={styling.linkNumber}>02.</span> Blog
        </NavLink>
        <NavLink exact to="/projects" activeClassName={styling.activeLink}>
          <span className={styling.linkNumber}>03.</span> Projects
        </NavLink>

        {/* <ToggleButton /> */}
      </div>
      <div
        className={styling.burgerMenuBar}
        onClick={() => setOpenBurgerMenu(true)}
      >
        <div className={styling.slice1}></div>
        <div className={styling.slice2}></div>
        <div className={styling.slice3}></div>
      </div>
      {openBurgerMenu ? <Sidebar closeSidebar={setOpenBurgerMenu} /> : null}
    </div>
  );
}
