import React from "react";
import styling from "./About.module.css";
import UserProfile from "../../../Assets/avatar.png";
import Heading from "./../../../UI/Reusable/Heading/Heading";
export default function About() {
  return (
    <div className={styling.AboutPage}>
      <Heading title="About Me." />
      <div className={styling.AboutContent}>
        <div className={styling.AboutText}>
          <div className={styling.text}>
            <p>
              I'm Suhel, a software developer based in India. I have been
              working with web for 1+ year. I develop high-performing,
              pixel-perfect user interfaces.
            </p>
            <p>
              Currently i am working as a Frontend Developer at{" "}
              <a
                href="https://www.playshifu.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                @playshifu
              </a>
              . I am also the mentor in Coding Coach where i mentor beginners in
              the field of web development.
              {/* I
              am very much active on twitter ({" "}
              <a
                href="https://twitter.com/ProgrammingEra"
                target="_blank"
                rel="noopener noreferrer"
              >
                @programmingEra with 3100+ followers{" "}
              </a>
              ) where i share valuable resources related to programming. */}
              {/* Currently, i am looking for a job. */}
            </p>
            <p>Here are a few technologies I've been working with recently:</p>
          </div>
          <div className={styling.TechStack}>
            <p>▹ JavaScript (ES6+)</p>
            <p>▹ HTML & (S)CSS</p>
            <p>▹ React</p>
            <p>▹ Vue</p>
            <p>▹ React Native</p>
            <p>▹ Firebase</p>
            <p>▹ Node.js</p>
          </div>
        </div>
        <div className={styling.AboutImage}>
          <div className={styling.actualImage}>
            <img src={UserProfile} alt="user avatar" />
            <div className={styling.lineOverlay}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
