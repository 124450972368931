import React from "react";
import styling from "./HomeProject.module.css";
import Button from "../../../UI/Reusable/Button/Button";
import { NavLink } from "react-router-dom";
import Heading from "../../../UI/Reusable/Heading/Heading";

export default function HomeProject() {
  return (
    <div className={styling.HomeProject}>
      <Heading title="My Projects." />

      <p className={styling.textContent}>
        Passionate about technology I never refrain from testing the concepts by
        applying them on to real time scenarios. Here's a list of projects that
        I have built during my college days ranging from basic to intermediate,
        these includes toc-tac-toe, weather app, COVID-19 tracking to name a
        few. Dive-in into my space to have a glimpse of my Development
        Environment!
      </p>
      <NavLink to="/projects">
        <Button title="View My Projects" />
      </NavLink>
    </div>
  );
}
