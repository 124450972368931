import React from "react";
import styling from "./GetInTouch.module.css";
import Button from "../../../UI/Reusable/Button/Button";

export default function GetInTouch() {
  return (
    <div className={styling.GetInTouch}>
      <p className={styling.whatNext}>What's Next?</p>
      <h1>Get In Touch</h1>
      <p className={styling.aboutWhatNext}>
        I'm currently looking for any new opportunities, my inbox is always
        open. Whether you have a question or just want to say hi, I'll try my
        best to get back to you!
      </p>
      <a
        href="mailto:suhelhasan202@gmail.com?subject=Contacting threw website"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        <Button title="Get In Touch" />
      </a>
    </div>
  );
}
