import React from "react";
import styling from "./Blog.module.css";
import { Link } from "react-router-dom";

export default function Blog({ details }) {
  return (
    <div className={styling.Blog} key={details.id}>
      <div className={styling.cardImage}>
        <img src={details.coverPage} alt="" srcset="" />

        <Link to={details.link}>
          <div className={styling.cardOverlay}></div>
        </Link>
      </div>

      <div className={styling.cardText}>
        <div className={styling.cardTitle}>
          <Link to={details.link}>
            <p className={styling.headingLink}>{details.title}</p>
          </Link>
        </div>
        <p className={styling.borderBottom}></p>
        <div className={styling.cardDescription}>
          <p>{details.description.slice(0, 60)}...</p>
        </div>

        <div className={styling.cardBottom}>
          <div>
            <Link to={details.link}>
              <button>View More</button>
            </Link>
          </div>
          <div className={styling.cardBottomLinks}>
            <p>{details.publishDate}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
