import React from "react";
import styling from "./Footer.module.css";
import { FaGithub, FaLinkedinIn, FaCodepen } from "react-icons/fa";
// import { FiYoutube } from "react-icons/fi";

export default function Footer() {
  return (
    <div className={styling.Footer}>
      <div className={styling.FooterIcons}>
        <div>
          <a
            href="https://github.com/suhelhasan"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub />
          </a>
        </div>
        {/* <div>
          <a
            href="https://twitter.com/ProgrammingEra"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter />
          </a>
        </div> */}
        <div>
          <a
            href="https://www.linkedin.com/in/suhelhasan11/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedinIn />
          </a>
        </div>
        <div>
          <a
            href="https://codepen.io/suhelhasan/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaCodepen />
          </a>
        </div>
        {/* <div>
          <a
            href="https://www.youtube.com/channel/UCUnShWlwJ57J6tZ6pmDFObg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FiYoutube />
          </a>
        </div> */}
      </div>
      <a
        href="mailto:suhelhasan202@gmail.com?subject=Contacting threw website"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        <p>If you need any help, ping me !</p>
      </a>
    </div>
  );
}
