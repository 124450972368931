import axios from "axios";

export const github = async () => {
  try {
    let { data } = await axios.get(
      "https://api.github.com/users/suhelhasan/repos"
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};
