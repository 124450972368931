import React from "react";
import styling from "./Heading.module.css";

export default function Heading({ title, moveRight }) {
  return (
    <div
      className={styling.Title}
      style={moveRight ? { alignItems: "flex-end" } : null}
    >
      <p>{title}</p>
      <div className={styling.headingBorder1}></div>
      <div className={styling.headingBorder2}></div>
    </div>
  );
}
