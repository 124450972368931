import React from "react";
import styling from "./PageNotFound.module.css";
import image404 from "../../Assets/404.svg";
export default function PageNotFound() {
  return (
    <div className={styling.PageNotFound}>
      <div>
        <img src={image404} alt="404" />
      </div>
      <h2>
        <a href="/">Click Here</a> to Visit <a href="/">HOME PAGE</a>
      </h2>
    </div>
  );
}
